<template>
  <div id="forDiag">
    <div class="page1">
      <!--面包屑-->
      <!-- <div class="breadcrumb"></div> -->
      <!--主体内容-->
      <div class="content">
        <!--搜索条件区域-->
        <div class="searchWrapper" @keydown.enter.prevent="searchData">
          <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
          <el-form
            :inline="true"
            label-position="right"
            label-width="90px"
            :model="formInline"
            class="demo-form-inline"
            ref="formData"
          >
            <!-- <el-form-item :label="$t('searchModule.role')">
            <el-select v-model.trim="formInline.systemRoleId" filterable style = "width: 220px;">
              <el-option label="全部" value=""></el-option>
              <el-option :label="value.bossManagerName" :value="value.bossManagerRoleId" :key="value.resourceIds" v-for="value in roleList"></el-option>
            </el-select>
            </el-form-item>-->
            <div class="col_box">
              <div class="col_left">
                <el-form-item :label="$t('searchModule.Login_Name')" prop="account">
                  <el-input
                    :maxlength="20"
                    v-model.trim="formInline.account"
                    placeholder="请输入登录名"
                  ></el-input>
                </el-form-item>
                <el-form-item :label="$t('searchModule.full_name')" prop="bossManagerName">
                  <el-input
                    :maxlength="20"
                    v-model.trim="formInline.bossManagerName"
                    placeholder="请输入姓名"
                  ></el-input>
                </el-form-item>
                <el-form-item :label="$t('searchModule.phone_number')" prop="mobile">
                  <el-input
                    :maxlength="11"
                    v-model.trim="formInline.mobile"
                    placeholder="请输入手机号"
                  ></el-input>
                </el-form-item>
                <el-form-item :label="$t('searchModule.Department_to')" prop="subDepartments">
                  <!-- <el-select v-model.trim="formInline.subDepartments" filterable  size="15">
              <el-option label="全部" value="">全部</el-option>
              <el-option label="在职" value="1">在职</el-option>
              <el-option label="离职" value="2">离职</el-option>
              </el-select>-->
                  <!-- <SelectTree :props="props"
                          :options="optionData"
                          :value="valueId"
                          :clearable="isClearable"
                          :accordion="isAccordion"
              @getValue="getValue($event)" />-->
                  <el-cascader
                    :options="treeData"
                    expand-trigger="click"
                    filterable
                    clearable
                    :show-all-levels="false"
                    v-model="formInline.deptId"
                    :props="defaultProps"
                    change-on-select
                    @change="handleChange"
                  ></el-cascader>
                </el-form-item>
                <el-form-item :label="$t('searchModule.state')" prop="bossManagerState">
                  <el-select v-model.trim="formInline.bossManagerState" filterable size="15">
                    <el-option label="全部" value>全部</el-option>
                    <el-option label="在职" value="1">在职</el-option>
                    <el-option label="离职" value="2">离职</el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div class="col_right">
                <el-button
                  type="primary"
                  icon="el-icon-search"
                  @click="
                    pageNum = 1;
                    searchData();
                  "
                  :loading="loading"
                  >{{ $t('button.search') }}</el-button
                >
                <el-button icon="el-icon-delete" plain @click="clearData('formData')"
                  >{{ $t('button.reset') }}</el-button
                >
              </div>
            </div>
            <div class="col_box_boder" v-if="$route.meta.authority.button.add"></div>
            <div class="col_box h44">
              <div class="col_left">
                <router-link :to="{ path: '/administratorManage-AE' }">
                  <el-button
                    v-if="$route.meta.authority.button.add"
                    type="primary"
                    icon="el-icon-plus"
                    style="margin-left: 10px"
                    >{{ $t('button.addto') }}</el-button
                  >
                </router-link>
              </div>
            </div>
          </el-form>
        </div>
        <!--列表区域-->
        <div class="tableWrapper bgFFF paddingB10">
          <el-table v-loading="loading" :data="tableData" style="width: 100%">
            <!--<el-table-column type="index" :label="$t('list.index')" width="70" align='center'></el-table-column>-->
            <el-table-column
              :prop="item.prop"
              :label="item.label"
              align="center"
              :width="item.width"
              v-for="item in tableCols"
              :key="item.prop"
              :formatter="item.formatter"
            ></el-table-column>
            <!-- <el-table-column :label="$t('searchModule.state')" width="120" align='center'>
            <template slot-scope='scope'>
              <span><i :class="scope.row.bossManagerState == 1 ?'el-icon-circle-check':'el-icon-circle-close'" :style='{"color":scope.row.bossManagerState == 1 ?"green":"red"}'></i></span>
            </template>
            </el-table-column>-->
            <el-table-column
              label="操作"
              width="80"
              align="center"
              v-if="
                $route.meta.authority.button.view ||
                $route.meta.authority.button.edit ||
                $route.meta.authority.button.quit ||
                $route.meta.authority.button.reset
              "
            >
              <template slot-scope="scope">
                <el-dropdown
                  style="color: #20a0ff; cursor: pointer"
                  @command="handleCommand($event, scope.row)"
                >
                  <el-button type="text" size="small" style="padding: 0">
                    操作
                    <i class="el-icon-arrow-down" />
                  </el-button>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="1" v-if="$route.meta.authority.button.view"
                      >查看</el-dropdown-item
                    >
                    <el-dropdown-item
                      command="2"
                      v-show="scope.row.admin != 1"
                      v-if="$route.meta.authority.button.edit"
                      >编辑</el-dropdown-item
                    >
                    <el-dropdown-item
                      command="3"
                      v-show="scope.row.admin != 1"
                      v-if="$route.meta.authority.button.quit"
                      >{{ scope.row.bossManagerState == "1" ? "离职" : "在职" }}</el-dropdown-item
                    >
                    <el-dropdown-item
                      command="4"
                      v-show="scope.row.admin != 1"
                      v-if="$route.meta.authority.button.reset"
                      >重置密码</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>
              </template>
            </el-table-column>
          </el-table>
          <!--分页器-->
          <div class="pagerWrapper">
            <div class="block">
              <el-pagination
                v-if="total != 0"
                @current-change="handleCurrentChange"
                :current-page="pageNum"
                :page-size="pageSize"
                layout="total, prev, pager, next, jumper"
                :total="total"
              ></el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//   import {
//
//    setIndex,
//    jsMd5
//   } from '../../common/js/public.js'
import SelectTree from "./treeSelect.vue";
import treeJson from "./tree.json";
import { jsMd5 } from "@/common/js/public.js";
export default {
  name: "administratorManage",
  data() {
    return {
      treeData: [],
      selectedId: "",
      defaultProps: {
        children: "childNodes",
        label: "deptName",
        value: "deptId",
      },
      isClearable: true, // 可清空（可选）
      isAccordion: true, // 可收起（可选）
      valueId: "", // 初始ID（可选）
      props: {
        // 配置项（必选）
        value: "id",
        label: "name",
        children: "children",
        // disabled:true
      },
      list: [],
      isAdmin: false,
      index: 0,
      total: 0,
      pageSize: 15,
      isEdit: false,
      pageNum: 1,
      userId: "",
      loading: false,
      roleList: [],
      areaList: [],
      limitList: [],
      tableCols: [
        {
          prop: "account",
          label: "登录用户名",
          width: "",
        },
        {
          prop: "bossManagerName",
          label: this.$t("list.full_name"),
          width: "120",
        },
        {
          prop: "sex",
          label: "性别",
          width: "120",
          formatter: (row) => {
            if (row.sex == 1) {
              return "男";
            } else if (row.sex == 0) {
              return "女";
            }
          },
        },
        {
          prop: "mobile",
          label: this.$t("list.phone_number"),
          width: "130",
        },
        {
          prop: "deptName",
          label: this.$t("list.Department_to"),
          width: "",
        },
        {
          prop: "arealist",
          label: "管辖区域",
          width: "",
          formatter: (row) => {
            let data = row.arealist;
            let roleList = [];
            data.forEach((item) => {
              roleList.push(item.bossManagerAreaName);
            });
            return roleList.toString();
          },
        },
        {
          prop: "bossManagerRoleName",
          label: this.$t("list.role"),
          width: "95",
        },
        {
          prop: "bossManagerState",
          label: this.$t("list.state"),
          width: "95",
          formatter: (row) => {
            if (row.bossManagerState == "1") {
              return "在职";
            } else if (row.bossManagerState == "2") {
              return "离职";
            } else if (row.bossManagerState == "0") {
              return "全部";
            }
          },
        },
      ],
      tableData: [],
      formInline: {
        bossManagerState: "",
        systemRoleId: "",
        subDepartments: "",
        bossManagerName: "",
        mobile: "",
        account: "",
        deptId: "",
      },
    };
  },
  methods: {
    // 清空选项
    clearData(formName) {
      this.$refs[formName].resetFields();
      this.formInline.deptId = "";
    },
    // 操作细则
    handleCommand(command, rowData) {
      if (command == 1) {
        this.$router.push({
          path: "/administratorManageDetail",
          query: rowData,
        });
      } else if (command == 2) {
        this.$router.push({
          path: "/administratorManage-AE",
          query: rowData,
        });
      } else if (command == 3) {
        this.managerSystem(rowData);
      } else if (command == 4) {
        this.$confirm("将此账号的登录密码重置为Zhht@2019，是否继续?", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.resetPassword(rowData);
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消操作",
            });
          });
      }
    },
    // 重置密码
    resetPassword(rowInfo) {
      this.$axios
        .post("/acb/2.0/bossManager/resetpassword", {
          data: {
            bossManagerId: rowInfo.bossManagerId,
            newPassword: jsMd5(rowInfo.account, "Zhht@2019"),
          },
        })
        .then((res) => {
          this.$message({
            type: "success",
            message: "重置成功",
          });
          this.searchData();
        });
    },
    // 分页处理
    handleCurrentChange(val) {
      this.pageNum = val;
      this.searchData();
    },

    updatestate(systemData) {
      this.$axios
        .post("/acb/2.0/bossManager/updatestate", {
          data: {
            bossManagerId: systemData.bossManagerId,
            bossManagerState: systemData.bossManagerState == 1 ? 2 : 1,
          },
        })
        .then((res) => {
          this.$message({
            type: "success",
            message: systemData.bossManagerState == 1 ? "离职操作成功!" : "在职操作成功!",
          });
          this.searchData();
        });
    },

    // 离职
    managerSystem(systemData) {
      var tip = systemData.bossManagerState == 1 ? "离职" : "在职";
      this.$confirm("此操作将" + tip + "该用户, 是否继续?", this.$t('pop_up.Tips'), {
        confirmButtonText: this.$t('pop_up.Determine'),
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (systemData.bossManagerState) {
            this.updatestate(systemData);
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消" + tip,
          });
        });
    },

    /* 角色的 */
    getRoleList() {
      let opt = {
        method: "get",
        url: "/acb/2.0/bossManagerRole/list",
        data: {
          page: 1,
          size: 0,
        },
        success: (res) => {
          this.loading = false;
          if (res.state == 0) {
            this.roleList = res.value.list;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
      };
      this.$request(opt);
    },
    // 搜索
    searchData() {
      this.$axios
        .get("/acb/2.0/bossManager/list", {
          data: {
            page: this.pageNum,
            size: this.pageSize,
            account: this.formInline.account,
            bossManagerName: this.formInline.bossManagerName,
            systemRoleId: this.formInline.systemRoleId,
            mobile: this.formInline.mobile,
            bossManagerState: this.formInline.bossManagerState,
            deptId: this.formInline.deptId,
          },
        })
        .then((res) => {
          this.loading = false;
          if (res.state == 0) {
            // this.tableData = setIndex(this.pageNum, res.value.list);
            this.tableData = this.setIndex(this.pageNum, res.value.list);
            this.total = res.value.total * 1 || 0;
          } else if (res.state == 4) {
            this.formInline.systemRoleId = "";
            this.formInline.bossManagerName = "";
            this.formInline.account = "";
            this.formInline.deptId = "";
            this.$alert("您搜索的数据不存在", this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            }).then(() => {
              this.searchData();
            });
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    // index
    setIndex(page, arr) {
      arr.forEach((value, index) => {
        value.index = (page - 1) * 15 + index + 1;
      });
      return arr;
    },
    // 提交数据
    submitData() {
      if (this.isEdit) {
        // 修改管理员
        this.$refs["formUpdate"].validate((valid) => {
          if (valid) {
            this.update();
          } else {
            return false;
          }
        });
      } else {
        // 添加管理员
        this.$refs["formAdd"].validate((valid) => {
          if (valid) {
            this.addUser();
          } else {
            return false;
          }
        });
      }
    },
    // 添加管理员
    addUser() {
      this.formUpdate.password = jsMd5(this.formUpdate.account, this.formUpdate.password);
      let opt = {
        method: "post",
        url: "/boss/2.0/systems/create",
        data: this.formUpdate,
        success: (res) => {
          if (res.state == 0) {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            }).then(() => {
              this.dialogVisible = false;
              this.searchData();
            });
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            }).then(() => {
              this.dialogVisible = false;
              this.searchData();
            });
          }
        },
      };
      this.$request(opt);
    },
    add(data) {
      if (!this.isEdit) {
        this.formUpdate = {
          systemRoleId: "",
          role: "",
          account: "",
          password: "",
          bossManagerName: "",
          sex: 1,
          mobile: "",
          email: "",
          systemType: "1",
          systemRoleName: "",
        };
      } else {
        let {
          systemId,
          bossManagerName,
          account,
          mobile,
          systemRoleId,
          email,
          systemRoleName,
          sex,
          systemType = "1",
        } = data;
        this.oldmobile = mobile;
        this.formUpdate = {
          systemId,
          bossManagerName,
          account,
          mobile,
          systemRoleId,
          email,
          systemRoleName,
          sex,
          systemType,
        };
      }
    },
    // 修改管理员
    update() {
      this.isEdit = true;
      let opt = {
        method: "post",
        url: "/boss/2.0/systems/update/" + this.formUpdate.systemId,
        data: this.formUpdate,
        success: (res) => {
          if (res.state == 0) {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            }).then(() => {
              this.dialogFormVisible = false;
              this.searchData();
            });
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            }).then(() => {
              this.dialogFormVisible = false;
              this.searchData();
            });
          }
        },
      };
      this.$request(opt);
    },
    // 获取所属部门
    getByCurrentUser() {
      this.$axios.get("/acb/2.0/dept/getByCurrentUser").then((res) => {
        this.treeData = [];
        this.treeData.push(res.value);
        this.treeData = [res.value];
      });
    },
    handleChange(value) {
      this.formInline.deptId = value[value.length - 1];
    },
    // 树形select取值
    getValue(value) {
      this.valueId = value;
    },
    getNodeTree(tree) {
      for (var i in tree) {
        this.list.push({
          id: tree[i]["id"],
          parentId: tree[i]["parentId"],
          name: tree[i]["goodsTypeName"],
        });
        if (tree[i].children.length > 0) {
          this.getNodeTree(tree[i].children);
        }
      }
    },
  },
  components: {},
  activated() {
    this.searchData();
    this.getByCurrentUser();
  },
  created() {
    if (this.$route.query.parkTenantId) {
      this.formInline.parkTenantId = this.$route.query.parkTenantId;
    }
    // 是否是超级管理员
    if (sessionStorage.admin == 1) {
      this.isAdmin = true;
    }
    // this.getTenant();
    // this.searchData();
    this.getRoleList();
    // 树形tree
    // this.getNodeTree(treeJson.data);
    // let obj = {};
    // this.list = this.list.reduce((item, next) => {
    //   obj[next.id] = obj[next.id] ? "" : (obj[next.id] = true && item.push(next));
    //   return item;
    // }, []);
  },
  mounted() {},
  computed: {
    /* 转树形数据 */
    optionData() {
      let cloneData = JSON.parse(JSON.stringify(this.list)); // 对源数据深度克隆
      return cloneData.filter((father) => {
        // 循环所有项，并添加children属性
        let branchArr = cloneData.filter((child) => father.id == child.parentId); // 返回每一项的子级数组
        // branchArr.length = branchArr.length > 0 ? (father.children = branchArr) : ""; // 给父级添加一个children属性，并赋值
        if (branchArr.length) {
          father.children = branchArr;
        }
        father.children = branchArr;
        return father.parentId == 0; // 返回第一层
      });
    },
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.breadcrumb {
  height: 35px;
}

.content {
  overflow: hidden;

  .pagerWrapper {
    text-align: right;
    margin-top: 28px;
    font-size: 12px;

    .export {
      font-size: 12px;

      .iconfont {
        margin-right: 0;
      }
    }
  }
}

.detail {
  width: 61.4%;
  height: 82.9%;
  position: absolute;
  top: 8.6%;
  left: 20.1%;
  background: #FFFFFF;
  box-shadow: 0 0 4px 1px rgba(128, 145, 165, 0.3);
  border-radius: 3px;
  z-index: 1000;
  box-sizing: border-box;
  padding: 20px 0;

  .title {
    position: absolute;
    background: #3F4A56;
    border-radius: 3px 3px 0 0;
    color: #fff;
    width: 100%;
    top: 0;
    left: 0;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    padding: 0 12px;
    box-sizing: inherit;
    z-index: 100;

    .el-icon-close {
      float: right;
      margin-top: 14px;
    }
  }

  .info {
    margin-top: 20px;
    padding: 2.1%;
    height: 100%;
    overflow: auto;
    box-sizing: inherit;
  }
}

.mask {
  background: rgba(49, 53, 65, 0.6);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}
</style>
<style>
#forDiag .el-dialog {
  width: 500px;
}
#forDiag .el-form-item__content {
  margin-left: 10px !important;
}
</style>
